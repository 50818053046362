// src/data/blogData.js

import Blog_1 from "../blogs/Blog_1";
import Blog_2 from "../blogs/Blog_2";
const siteUrl = "https://firekirin777slots.com";
let blogItems = [
  {
    id: "1",
    slug: "tips-to-win-big-on-online-sweepstakes-slots",
    image: "/assets/carousel/sweepstakes-slots.png",

    metaTitle: "Tips to Win Big on Online Sweepstakes Slots",
    title: "Tips to Win Big on Online Sweepstakes Slots",
    content:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    metaDescription:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    canonical: siteUrl + "/blog/tips-to-win-big-on-online-sweepstakes-slots",

    ogTitle: "Tips to Win Big on Online Sweepstakes Slots",
    ogDescription:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    ogImage: siteUrl + "/assets/carousel/sweepstakes-slots.png",
    component: Blog_1,
    sharingUrl: siteUrl + "/blog/tips-to-win-big-on-online-sweepstakes-slots",
    date: "2024-11-25",
  },
  {
    id: "2",
    slug: "your-ultimate-online-sweepstakes-casino-experience",
    image: "/assets/carousel/casino.jpg",

    metaTitle:
      "Discover the Thrills of FireKirin: Your Ultimate Online Sweepstakes Casino Experience",
    title:
      "Discover the Thrills of FireKirin: Your Ultimate Online Sweepstakes Casino Experience",
    content:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    metaDescription:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    canonical:
      siteUrl + "/blog/your-ultimate-online-sweepstakes-casino-experience",

    ogTitle:
      "Discover the Thrills of FireKirin: Your Ultimate Online Sweepstakes Casino Experience",
    ogDescription:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    ogImage: siteUrl + "/assets/carousel/casino.jpg",
    component: Blog_2,
    sharingUrl:
      siteUrl + "/blog/your-ultimate-online-sweepstakes-casino-experience",
    date: "2025-01-17",
  },
];

let blogData = blogItems.sort((a, b) => new Date(b.date) - new Date(a.date));
export default blogData;
