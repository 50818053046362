import React from "react";
import Home from "./pages/Home";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "reactstrap/lib";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Layout from "./components/Layout";
import BlogsCategory from "./blogs/BlogsCategory";
import BlogLayout from "./blogs/BlogLayout";
import Disclaimer from "./pages/DIsclaimer";
import TermCondition from "./pages/TermCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const router = createBrowserRouter([
  {
    path : '/',
    element : <Layout />,
    children : [
      {
        index : true,
        element : <Home />
      },
      {
        path : '/blogs',
        element : <BlogsCategory />
      },
      {
        path : '/blog/:slug',
        element : <BlogLayout />
      },
      {
        path : '/disclaimer',
        element : <Disclaimer />
      },
      {
        path : '/terms-conditions',
        element : <TermCondition />
      },
      {
        path : '/privacy-policy',
        element : <PrivacyPolicy />
      },
      {
        path : '*',
        element : <NotFound />
      },

    ]
  }
])


const App = () => {
  return (
    <React.Fragment>
    <RouterProvider router={router} />
    </React.Fragment>
  );
};

export default App;