import React from "react";
import { useNavigate } from "react-router-dom";
const BlogCard = (props) => {
  const navigate = useNavigate();
  return (
    <div className="col-md-6 ">
      <div class="common-blog ">
        <img src={props.image} alt="" />
        <div class="common-blog-content">
          <h4>{props.title}</h4>
          <button onClick={() => navigate(`/blog/${props.slug}`)}>
            Read More
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
