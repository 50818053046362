import React from "react";
import { Row, Col, Container, Card } from "reactstrap";
import "../styles/Banner.css";

const Banner = () => {
  return (
    <div className="banner-bg">
      <div className="banner-wrapper">
        <div className="the-ultimate-fish">
          The Ultimate Fish Games and Slots
        </div>
        <h1 className="fire-kirin-online container_h1">
          Play Sweepstakes & Fish Games Anytime, Anywhere with Fire Kirin!
        </h1>
        <button
          className="banner-button"
          onClick={() =>
            window.open("https://www.facebook.com/Firekirinonline77", "_blank")
          }
        >
          <span className="get-free-fire">Get Free Fire Kirin Account</span>
        </button>
        <div className="banner-container">
          <div className="now-play">NOW PLAY DIRECTLY WITH US!</div>
        </div>

        <h2 className="official-facebook-pages py-4">
          OFFICIAL FACEBOOK PAGES
        </h2>
        <div className="please-contact-our-container">
          <div>Please contact our official</div>
          <div>Partners for account creation</div>
        </div>
        <Container className="d-flex justify-content-center py-sm-2 py-md-3 py-lg-5">
          <Row>
            <Col sm="12" md="6" className="card-gamming-wrapper">
              <Card className="card-gamming-social">
                <div className="vip-gaming">VIP GAMING</div>
                <div className="vip-gaming-is-social">
                  VIP Gaming is known for unmatched customer service, instant
                  cash-in and cash-outs and 24/7 support. Join them and
                  experience the thrill of online games.
                </div>
                <div className="facebook-fill">
                  <img
                    loading="lazy"
                    alt=""
                    src="/assets/icon/facebook-icon.png"
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/profile.php?id=61551686566639&mibextid=ZbWKwL",
                        "_blank"
                      )
                    }
                  />
                </div>
              </Card>
            </Col>
            <Col sm="12" md="6" className="card-gamming-wrapper">
              <Card className="card-gamming-social">
                <div className="vip-gaming">Firekirin SLOTS </div>
                <div className="vip-gaming-is-social">
                  Join Firekirin slots to receive 200% bonus on the first
                  deposit.
                </div>
                <div className="facebook-fill">
                  <img
                    loading="lazy"
                    alt=""
                    src="/assets/icon/facebook-icon.png"
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/Firekirinonline77?mibextid=ZbWKwL",
                        "_blank"
                      )
                    }
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Banner;
