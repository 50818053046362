import React from 'react'
import blogData from '../data/blogData'
import { Link } from 'react-router-dom'
const RecentPosts = () => {
    return (
      <div class="recent-post">
        <h3>Recent Posts</h3>
        {blogData.map((blog, index) => (
          <>
            <div class="common-post" key={index}>
              <Link to={`/blog/${blog.slug}`}>
                <img src={blog.image} alt="" />
                <p>{blog.metaTitle}</p>
                {/* <button key={index} onClick={() => navigate(`/blog/${blog.slug}`)}>Read More</button> */}
              </Link>
            </div>
          </>
        ))}
      </div>
    );
}

export default RecentPosts
