import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Navbar,
  // NavbarBrand,
  NavbarToggler,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavItem,
  Collapse,
} from "reactstrap";
import "../styles/NavBar.css";

const NavBar = () => {
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  const toggleOffcanvas = () => setOffcanvasOpen(!offcanvasOpen);
  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        style={{
          position: "sticky",
          top: "45px",
          zIndex: "111",
          backgroundColor: "#0000008f",
        }}
      >
        <div className="nav-logo">
          <NavLink to={'/'}>
            <img alt="logo" src="/assets/nav-logo.svg" />
          </NavLink>
        </div>
        <NavbarToggler onClick={toggleOffcanvas} />
        <Collapse navbar>
          <Nav className="ms-auto align-items-center" navbar>
            <NavItem>
              <NavLink to="/" className="nav-font nav-link">
                About Fire Kirin
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/blogs" className="nav-font nav-link">
                Blogs
              </NavLink>
            </NavItem>
            <NavItem>
              <button
                className="mx-3 nav-button"
                type="submit"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/Firekirinonline77",
                    "_blank"
                  )
                }
              >
                Create Free account
              </button>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>

      <Offcanvas
        isOpen={offcanvasOpen}
        toggle={toggleOffcanvas}
        direction="end"
      >
        <OffcanvasHeader toggle={toggleOffcanvas}>
          Offcanvas Title
        </OffcanvasHeader>
        <OffcanvasBody>
          <Nav vertical>
            <NavItem>
              <NavLink to="/">About Fire Kirin</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/blogs">Blog</NavLink>
            </NavItem>
          </Nav>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default NavBar;
