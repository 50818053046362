import React from 'react'
import { Helmet } from 'react-helmet'

const TermCondition = () => {
    return (
        <>
            <Helmet>
                <title>Fire Kirin 777 Slots - Terms and Conditions</title>
                <meta name="description" content="Review our Terms and Conditions to understand the rules and guidelines for using our site and ensure a secure experience." />
            </Helmet>
            <section class="all-blog">
                <div class="container">
                    <div class="blog-head blog-details">
                        <h1>Terms and Conditions</h1>
                    </div>
                    <div class="row recent-blog gy-4">
                        <div class="col-lg-12">
                            <div class="common-terms-content">
                                <p>Welcome to Fire Kirin! These Terms and Conditions govern your use of our website and services. Please read them carefully, as they form a legally binding agreement between you ("the user") and Fire Kirin ("we," "us," "our").</p>

                                <div className='common-terms-one' id='online-slots'>
                                    <h4>1. Acceptance of Terms</h4>
                                    <p>By accessing or using our website, you agree to these terms and conditions. If you do not accept any part of these terms, you must stop using our website and services immediately. </p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>

                                    <h4>2. Eligibility</h4>
                                    <p>To use our services, you must be at least 18 years old or meet the legal age requirement in your jurisdiction. By accessing our website, you confirm that you meet this eligibility requirement.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>3. Account Registration</h4>
                                    <p>Certain features of Fire Kirin may require you to create an account. You agree to provide accurate and complete information during registration and to update your details as necessary. You are solely responsible for keeping your login credentials secure and for any activity that occurs under your account.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>4. Responsible Gaming</h4>
                                    <p>Fire Kirin promotes responsible gaming practices. We encourage users to view gaming as a form of entertainment, not as a way to earn income. If you feel that gaming is negatively impacting your life, we recommend seeking professional assistance. Tools like self-exclusion and deposit limits are available to support responsible play.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>5. Deposits and Withdrawals</h4>
                                    <p>All deposits made on Fire Kirin must originate from lawful sources. Withdrawals are subject to verification procedures to ensure security and compliance. We reserve the right to refuse or revoke any withdrawal request if fraudulent activity or a breach of these terms is detected.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>6. Bonuses and Promotions</h4>
                                    <p>Fire Kirin may offer bonuses, promotions, or special deals. Each promotion will have its own set of terms and conditions. Failure to comply with these rules may result in the forfeiture of bonuses and any related winnings.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>7. Fair Play and Security</h4>
                                    <p>We are committed to maintaining a fair and secure gaming environment. Any attempt to cheat, hack, or exploit a fair and secure gaming environment. Any attempt to cheat, hack, or exploit the system is strictly prohibited. Users engaging in such behavior will face account suspension or termination and may be reported to the relevant authorities.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>8. Intellectual Property</h4>
                                    <p>All content on Fire Kirin, including text, graphics, logos, and software, is protected by intellectual property laws. You may not copy, distribute, or modify any material on our site without prior written consent.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>9. Privacy Policy</h4>
                                    <p>Your use of Fire Kirin is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal data. We are committed to safeguarding your privacy with industry-standard measures.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>10. Account Suspension and Termination</h4>
                                    <p>We reserve the right to suspend or terminate your account at our discretion. Reasons may include a breach of these terms, fraudulent activity, or other behaviors deemed harmful to our platform.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>11. Updates to Terms and Conditions</h4>
                                    <p>Fire Kirin reserves the right to update or modify these terms and conditions at any time. It is your responsibility to review them regularly. Continued use of our services constitutes acceptance of any changes. </p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>12. Prohibited Activities</h4>
                                    <p>Engaging in activities that compromise the integrity of Fire Kirin is prohibited. This includes, but is not limited to, hacking, using automated tools, or attempting to disrupt the website’s functionality.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>13. Communications</h4>
                                    <p>By using our services, you agree to receive communications from Fire Kirin, including promotional offers and account updates. You may opt out of marketing communications while continuing to receive essential notifications.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>14. Links to Third-Party Websites</h4>
                                    <p>Our website may include links to third-party websites for your convenience. We do not endorse or control these sites and are not responsible for their content or any losses resulting from interactions with them. </p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>15. Liability and Indemnity</h4>
                                    <p>You agree to indemnify and hold Fire Kirin, its affiliates, and staff harmless from any claims, damages, or expenses arising from your use of our services or violation of these terms.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>16. Currency and Transactions</h4>
                                    <p>Fire Kirin supports multiple currencies. Please note that exchange rates and transaction fees may apply. It is your responsibility to be aware of any associated costs when making deposits or withdrawals.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>17. Responsible Conduct</h4>
                                    <p>We are committed to fostering a respectful and inclusive gaming environment. Users are expected to treat others with respect. Failure to adhere to community standards may result in account suspension.</p>
                                </div>
                                <div className='common-terms-one' id='online-slots'>
                                    <h4>18. Limitations of Liability</h4>
                                    <p>Fire Kirin is not liable for losses or damages resulting from the use of our website, including technical issues, service interruptions, or user errors.</p>
                                    <p>Thank you for choosing Fire Kirin. We aim to provide a fun and secure gaming experience!</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermCondition
