import React from "react";
import { Row, Col, Container } from "reactstrap";
import "../styles/Description.css";

const Description = () => {
  return (
    <React.Fragment>
      <div className="description-bg">
        <Container>
          <h2 className="what-exactly-is">
            Why Choose Fire Kirin for Sweepstakes Casino Games?
          </h2>
          <div className="fire-kirin-is">
            We offer many games, such as fish games, slots, and keno,
            guaranteeing many hours of fun and the chance of winning large. The
            key aspects that make us stand out are :
          </div>
          <Row className="variety-wrapper">
            <Col xs={12} sm={12} md={12} lg={4}>
              <div className="variety-of-games">Wide Variety of Games</div>
              <div className="from-the-fast-paced">
                Choose what you want to enjoy from the ocean of choices. From
                fish games to keno, we have something for everyone.
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <div className="variety-of-games">Play Anywhere</div>
              <div className="from-the-fast-paced">
                Easy, seamless access from the comfort of your computer. One can
                download our Fire Kirin app directly to play free casino games.
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <div className="variety-of-games">User-Friendly Experience</div>
              <div className="from-the-fast-paced">
                Our platform was created with enthusiastic users like you in
                mind. It offers easy navigation, secure transactions, and
                top-notch customer support.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Description;
