import React from 'react'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Fire Kirin 777 Slots - Privacy Policy</title>
                <meta name="description" content="We value your privacy and ensure your information is handled securely. Explore our policy to understand how we collect, use, and protect your data." />
            </Helmet>
            <section class="all-blog">
                <div class="container">
                    <div class="blog-head blog-details">
                        <h1>Privacy Policy</h1>
                    </div>
                    <div class="row recent-blog gy-4">
                        <div class="col-lg-12">
                            <div class="details-content">
                                <p>At Fire Kirin, your privacy is important to us, and we are committed to safeguarding your personal information. This privacy policy explains how we collect, use, share, and protect your information when you use our website or engage with our services. You agree to the practices described in this policy by accessing or using our platform. </p>

                                <div className='details-one' id='online-slots'>
                                    <h3>Information We Collect</h3>
                                    <h4>1. Information You Provide</h4>
                                    <p>We collect personal information you provide directly to us when:</p>
                                    <ul>
                                        <li><b>Creating an Account:</b> Name, email address, username, password, and phone number.</li>
                                        <li><b>Contacting Us for Support:</b> Information shared in messages or queries.</li>
                                        <li><b>Using the Platform:</b> Details you input, such as preferences or feedback.</li>
                                    </ul>
                                    <h4>2. Information You Provide</h4>
                                    <p>When you use our platform, we may collect information automatically, such as: </p>
                                    <ul>
                                        <li>Device type, operating system, and browser details.</li>
                                        <li>Unique device identifiers (e.g., IP address).</li>
                                        <li>Usage patterns and navigation data on our website.</li>
                                    </ul>
                                    <h4>3. Location Information</h4>
                                    <p>With your permission, we may use GPS or similar technologies to identify your location and personalize your experience, such as displaying region-specific content or offers. You can disable location services at any time in your device settings.</p>
                                </div>

                                <div className='details-one' id='online-slots'>
                                    <h3>How We Use Your Information?</h3>
                                    <p>We collect and process your information to:</p>
                                    <ul>
                                        <li>Create and manage your account.</li>
                                        <li>Personalize your experience with relevant content and services.</li>
                                        <li>Verify your identity for security and fraud prevention.</li>
                                        <li>Communicate updates, promotions, and support responses.</li>
                                        <li>Improve website functionality and user experience.</li>
                                        <li>Analyze data to enhance our services and resolve issues.</li>
                                    </ul>
                                </div>

                                <div className='details-one' id='online-slots'>
                                    <h3>Sharing Your Information</h3>
                                    <p>We only share your data in the following circumstances:</p>
                                    <ul>
                                        <li><b>Legal Obligations:</b> To comply with applicable laws or valid legal processes.</li>
                                        <li><b>Trusted Service Providers:</b> With third-party vendors who assist in operating our website and services, provided they adhere to our privacy standards.</li>
                                        <li><b>Business Transfers:</b> In cases of mergers, acquisitions, or sales of assets, where user data is among the assets transferred.</li>
                                        <li><b>Protecting Rights:</b> To safeguard the rights, safety, and security of our users or platform.</li>
                                    </ul>
                                    <p>We will not sell or rent your personal information to third parties.</p>
                                </div>

                                <div className='details-one' id='online-slots'>
                                    <h3>Your Rights and Choices</h3>
                                    <p>You have the following rights regarding your data:</p>
                                    <ul>
                                        <li><b>Access:</b> Request details about the information we hold about you.</li>
                                        <li><b>Correction:</b> Update inaccurate or incomplete personal details.</li>
                                        <li><b>Deletion:</b> Request the removal of your data, where applicable</li>
                                        <li><b>Restriction:</b> Limit the processing of your information in certain cases.</li>
                                        <li><b>Data Portability:</b> Obtain a copy of your data in a portable format.</li>
                                        <li><b>Withdraw Consent:</b> Revoke previously granted permissions for data processing.</li>
                                    </ul>
                                </div>

                                <div className='details-one' id='online-slots'>
                                    <h3>Data Retention</h3>
                                    <p>We retain your information for as long as your account is active or necessary to provide you with services. Automatically collected information is stored for a maximum of 24 months, after which it may be aggregated and anonymized.</p>
                                </div>

                                <div className='details-one' id='online-slots'>
                                    <h3>Cookies and Tracking Technologies</h3>
                                    <p>We use cookies to enhance your browsing experience. These cookies:</p>
                                    <ul>
                                        <li>Save your preferences for faster access</li>
                                        <li>Enable smooth navigation and login processes</li>
                                        <li>Collect data for analytics and performance improvement</li>
                                    </ul>
                                    <p>You can manage cookie preferences through your browser settings. However, disabling cookies may affect your user experience.</p>
                                </div>

                                <div className='details-one' id='online-slots'>
                                    <h3>Security Measures</h3>
                                    <p>We prioritize the security of your information. Our safeguards include:</p>
                                    <ul>
                                        <li>Data encryption for sensitive information.</li>
                                        <li>Access control policies limit data access to authorized personnel.</li>
                                        <li>Regular monitoring for potential vulnerabilities and threats.</li>
                                    </ul>
                                    <p>No system can guarantee absolute security despite our efforts. In case of a data breach, we will notify affected users within 72 hours.</p>
                                </div>

                                <div className='details-one' id='online-slots'>
                                    <h3>Embedded Content</h3>
                                    <p>Our platform may feature embedded content (e.g., videos and articles) from external websites. These third-party sites may collect data, use cookies, and monitor your interaction with their content. We recommend reviewing their privacy policies for more details.</p>
                                </div>

                                <div className='details-one' id='online-slots'>
                                    <h3>International Users</h3>
                                    <p>If you access our services from outside the USA, please note that your data may be stored and processed in the USA. The data protection laws in the USA may differ from those in your country, but we will take steps to ensure your privacy is protected.</p>
                                </div>

                                <div className='details-one' id='online-slots'>
                                    <h3>Policy Updates</h3>
                                    <p>This privacy policy may be updated periodically to reflect changes in our practices or for legal reasons. Updates will be posted on this page, and we may notify you via email or platform alerts. Please review this policy regularly to stay informed.</p>
                                </div>

                                <div className='details-one' id='online-slots'>
                                    <h3>Contact Us</h3>
                                    <p>If you have any questions or concerns about this Privacy Policy or how we handle your data, contact us through our official website.</p>
                                    <p>Thank you for trusting Fire Kirin with your personal information. Your privacy and security are our priorities.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy
