import React from "react";
import "../styles/Footer.css";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <React.Fragment>
            <div className="footer-bg">
                <Container>
                    <div className="footer-txt-wrapper">
                        <div className="footer-txt">
                            Fire Kirin is the hottest sweepstakes game suite available today.
                            Enjoy countless hours of mesmerizing gameplay from the comfort and
                            safety of your own home by accessing the <span className="fire-kirin-portal"> Fire Kirin online portal.</span>Experience the excitement of Fire Kirin safely at home by
                            visiting the online portal now.
                        </div>
                    </div>
                    <div className="footer-image">
                        <img alt="" src="../assets/footer-img.svg" />
                    </div>
                    <div className="footer-links">
                        <ul>
                            <li>
                                <Link className="nav-link nav-font" to='/disclaimer'>Disclaimer</Link>
                            </li>
                            <li>
                                <Link className="nav-link nav-font" to='/terms-conditions'>Terms and Conditions</Link>
                            </li>
                            <li>
                                <Link className="nav-link nav-font" to='/privacy-policy'>Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="copyright">
                        Copyright © 2024 Fire Kirin Online Game. All Rights Reserved.
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Footer;
